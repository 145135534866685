@import '../../styles/mixins.scss';

.pageWrapper main[menu-data='mobile-menu'] {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

main[menu-data='mobile-menu'] {
  .mainContainer__menu {
    height: 100vh;
    overflow-y: scroll;
    border-radius: 0;
    z-index: 10;
  }
}

.mainContainer {
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: #000;
  display: flex;
  width: 100%;
  position: relative;
  background: #fff;
  @include max-screen-768 {
    display: block;
  }
  &__menu {
    display: flex;
    flex-direction: column;
    flex: 0 0 210px;
    width: 210px;
    background: #262626;
    min-height: 100vh;
    position: relative;
    z-index: 10;
    padding: 0 20px 10px 30px;
    @include max-screen-768 {
      width: 100%;
      padding: 0;
      min-height: auto;
    }
  }
  &__content {
    position: relative;
    width: calc(100% - 210px);
    padding: 30px 25px 40px 60px;
    box-sizing: border-box;
    @include max-screen-768 {
      position: relative;
      width: 100%;
      padding: 20px 15px;
    }
    @include max-screen-510 {
      padding: 20px 10px;
    }
  }
  &__bottomBanner {
    width: 100%;
    height: auto;
  }
  &__wrap {
    width: 100%;
    // overflow: hidden;
  }

  button,
  select,
  input,
  textarea {
    font-family: Arial, sans-serif;
    outline: none;
  }
}
