@import '../../../../styles/mixins.scss';
.rtbBanner {
  width: 100%;
  height: auto;
  margin-top: 30px;
  @include max-screen-768 {
    margin-top: 20px;
  }
  &_mobile {
    display: none;
    @include max-screen-768 {
      display: block;
    }
  }
  &_desktop {
    @include max-screen-768 {
      display: none;
    }
  }
}
