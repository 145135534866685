.inputBtn {
  display: inline-block;
  font-size: 16px;
  color: white;
  font-weight: bold;
  padding: 11px 15px 11px;
  background-color: #0e920e;
  border-radius: 5px;
  overflow: hidden;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #367222;
  }
  &_disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}
