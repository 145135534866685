@import '../../../../styles/mixins.scss';

.stickyBanner {
  width: 100%;
  height: auto;
  position: sticky;
  bottom: 0;
  z-index: 7;
  transition: all 0.8s ease;
  display: none;
  @include max-screen-990 {
    display: block;
  }

  &_disable {
    position: fixed;
    bottom: -400px;
    animation: fade-out 0.5s ease-out forwards;
  }
  &__btn {
    position: absolute;
    display: inline-block;
    top: -18px;
    width: 45px;
    height: 20px;
    background-color: white;
    border-radius: 0 10px 0 0;
    cursor: pointer;
    z-index: 8;
    &:before {
      content: '';
      position: absolute;
      top: 16%;
      left: 50%;
      border: solid #161614;
      border-width: 0 2.6px 2.6px 0;
      display: inline-block;
      padding: 5px;
      transform: rotate(45deg) translate(-50%, 25%);
      opacity: 0.7;
      transition: opacity 0.3s ease;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
  &__wrap {
    padding: 2px 10px 0;
    background-color: white;
    box-shadow: -5px 0 40px 2px rgba(34, 60, 80, 0.24);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
