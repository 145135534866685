@import '../../../styles/mixins.scss';

/*Хлебные крошки*/
.breadcrumbsList {
  font-size: 12px;
  color: #767579;
  list-style-type: none;
  position: relative;
  margin-bottom: 38px;
  @include max-screen-768 {
    margin-bottom: 25px;
  }
  &__item {
    position: relative;
    display: inline-block;
    color: #909090;
    padding-right: 10px;
    &:after {
      content: '';
      border: 1px solid #c4c4c6;
      border-width: 2px 0 0 2px;
      width: 4px;
      height: 4px;
      display: inline-block;
      margin: 0 0 0 8px;
      transform: rotate(135deg);
      position: relative;
      top: -1px;
    }
    &:last-child {
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }
  &__itemLink {
    color: #999;
    transition: color 0.3s ease;
    &:hover {
      color: #106edc;
    }
    &_genre {
      display: inline-block;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
