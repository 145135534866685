@import '../../../styles/mixins.scss';

.loginForm {
  margin: 25px 0 0 0;
  max-width: 740px;
  height: auto;

  &__fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @include max-screen-510 {
      margin: 0;
    }
  }

  &__item {
    width: 50%;
    padding: 0 10px;
    @include max-screen-510 {
      width: 100%;
      padding: 0;
    }
  }

  &__separator {
    border-bottom: 1px solid #d9d9d9;
    margin: 20px 0;
  }

  &__controls {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__checkLabel {
    display: flex;
    cursor: pointer;
    font-size: 13px;
    line-height: 22px;
    color: #000;
  }

  &__checkInput {
    position: relative;
    appearance: none;
    height: 20px;
    width: 20px;
    padding: 1px;
    border: 1px solid #b7b7b7;
    background-color: #fafafa;
    border-radius: 3px;
    position: relative;
    margin-right: 6px;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 12px;
      background: url('/icons/check-mark-icon.svg') no-repeat 0 0;
      background-size: cover;
      opacity: 0;
    }
    &:checked {
      &::before {
        opacity: 1;
      }
    }
  }

  &__note {
    font-size: 13px;
    line-height: 22px;
    &_red {
      color: #ce0000;
    }
  }

  &__menu {
    display: flex;
    margin-top: 5px;
  }
  &__menuItem:first-child {
    margin-right: 25px;
  }
  &__menuLink {
    position: relative;
    font-size: 13px;
    line-height: 24px;
    padding-left: 24px;
    text-decoration: underline;
    color: #000;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -1px;
      width: 19px;
      height: 19px;
      background: url('/icons/register-icon.svg') no-repeat 0 0;
      background-size: contain;
    }
    &:hover {
      text-decoration: none;
    }
    &_password {
      padding-left: 20px;
      &:before {
        top: 0;
        width: 16px;
        height: 16px;
        background: url('/icons/key-icon.svg') no-repeat 0 0;
      }
    }
  }
}
