// Media Quares
// desktop breakpoints
$breakpoint-1920: 1920px;

// tablet breakpoints
$breakpoint-1366: 1366px;
$breakpoint-1060: 1060px;
$breakpoint-990: 991px;
$breakpoint-768: 769px;

// mobile breakpoints
$breakpoint-640: 640px;
$breakpoint-480: 480px;
$breakpoint-510: 511px;
$breakpoint-340: 341px;

// desktop queries
@mixin max-screen-1920 {
  @media screen and (max-width: ($breakpoint-1920 - 1)) {
    @content;
  }
}
@mixin min-screen-1920 {
  @media screen and (min-width: $breakpoint-1920) {
    @content;
  }
}
@mixin max-screen-1366 {
  @media screen and (max-width: ($breakpoint-1366 - 1)) {
    @content;
  }
}
@mixin min-screen-1366 {
  @media screen and (min-width: $breakpoint-1366) {
    @content;
  }
}

// tablet queries
@mixin max-screen-1060 {
  @media screen and (max-width: ($breakpoint-1060 - 1)) {
    @content;
  }
}
@mixin min-screen-1060 {
  @media screen and (min-width: $breakpoint-1060) {
    @content;
  }
}
@mixin max-screen-990 {
  @media screen and (max-width: ($breakpoint-990 - 1)) {
    @content;
  }
}
@mixin min-screen-990 {
  @media screen and (min-width: $breakpoint-990) {
    @content;
  }
}
@mixin max-screen-768 {
  @media screen and (max-width: ($breakpoint-768 - 1)) {
    @content;
  }
}

@mixin min-screen-768 {
  @media screen and (min-width: $breakpoint-768) {
    @content;
  }
}

// mobile queries
@mixin max-screen-640 {
  @media screen and (max-width: ($breakpoint-640 - 1)) {
    @content;
  }
}
@mixin min-screen-640 {
  @media screen and (min-width: $breakpoint-640) {
    @content;
  }
}
@mixin max-screen-510 {
  @media screen and (max-width: ($breakpoint-510 - 1)) {
    @content;
  }
}
@mixin min-screen-510 {
  @media screen and (min-width: $breakpoint-510) {
    @content;
  }
}
@mixin max-screen-480 {
  @media screen and (max-width: ($breakpoint-480 - 1)) {
    @content;
  }
}
@mixin min-screen-480 {
  @media screen and (min-width: $breakpoint-480) {
    @content;
  }
}
@mixin max-screen-340 {
  @media screen and (max-width: ($breakpoint-340 - 1)) {
    @content;
  }
}
@mixin min-screen-340 {
  @media screen and (min-width: $breakpoint-340) {
    @content;
  }
}
