@import '../../../styles/mixins.scss';

.menu {
  &__head {
    @include max-screen-768 {
      display: flex;
      align-items: center;
    }
    @include max-screen-640 {
      flex-wrap: wrap;
    }
  }
  &__mobBtn {
    display: none;
    cursor: pointer;
    opacity: 0.7;
    @include max-screen-768 {
      position: absolute;
      right: 20px;
      top: 15px;
      display: block;
      width: 20px;
      height: 18px;
    }
  }
  &__logotype {
    position: relative;
    padding: 14px 0 14px 0;
    margin-bottom: 25px;
    z-index: 0;
    @include max-screen-768 {
      padding: 8px 20px 8px 20px;
      margin-bottom: 0;
    }
    &:before {
      content: '';
      position: absolute;
      left: -30px;
      top: 0;
      z-index: -1;
      width: 210px;
      height: 97px;
      background: #424242;
      @include max-screen-768 {
        left: 0;
        width: 117px;
        height: 50px;
      }
    }
  }
  &__logotypeLink {
    position: relative;
    left: -5px;
    top: 6px;
    z-index: 1;
    display: flex;
    justify-content: center;
    @include max-screen-768 {
      left: 2px;
      top: 0;
    }
  }
  &__logotypeImg {
    width: 150px;
    height: 63px;
    opacity: 0.9;
    @include max-screen-768 {
      width: 77px;
      height: 34px;
    }
  }
  &__searchForm {
    position: relative;
    height: fit-content;
    margin-bottom: 26px;
    @include max-screen-768 {
      margin: 0 0 0 20px;
      width: calc(100% - 199px);
    }
    @include max-screen-640 {
      width: calc(100% - 20px);
      margin: 10px auto 10px;
    }
  }
  &__searchInput {
    display: block;
    width: 100%;
    height: 27px;
    border: 1px solid #3b393f;
    font-size: 14px;
    background: transparent;
    color: #fff;
    outline: none;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0 24px 0 7px;
    transition: all 0.3s ease 0s;
    @include max-screen-768 {
      height: 32px;
      color: #575757;
      background: white;
      border: none;
      border-radius: 2px;
    }
  }
  &__searchButton {
    position: absolute;
    right: 8px;
    top: 49%;
    transform: translateY(-50%);
    overflow: hidden;
    cursor: pointer;
    display: block;
    padding: 0;
    color: transparent;
    border: none;
    outline: none;
    width: 12px;
    height: 12px;
    background: url('/icons/search-icon.svg') no-repeat 0 0;
    background-size: cover;
    @include max-screen-768 {
      width: 20px;
      height: 20px;
    }
  }
  &__middle {
    margin-bottom: 10px;
    @include max-screen-768 {
      display: none;
      padding: 20px;
    }
  }
  &__bottom {
    margin-top: auto;
    font-size: 13px;
    color: #9d9c9f;
    @include max-screen-768 {
      display: none;
    }
  }
  &__emailInfo {
    margin-bottom: 20px;
  }
  &__emailTitle {
    margin-bottom: 5px;
  }
  &__email {
    color: white;
    text-decoration: underline;
    transition: color 0.3s ease;
    &:hover {
      color: #9d9c9f;
      text-decoration: underline;
    }
  }
}

//Пункты меню
.menuList {
  position: relative;
  margin-bottom: 30px;
  &_main {
    margin-bottom: 20px;
    .menuList__item a {
      color: #9d9c9f;
      &:hover {
        color: #c4c4c6;
      }
    }
  }
  &__item {
    &.active ~ .menuList__genres {
      display: block;
    }
    &_toggle {
      cursor: pointer;
      font-weight: bold;
      color: #ffd700;
      position: relative;
      margin-top: 20px;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
      &:after {
        content: '';
        position: absolute;
        left: 86px;
        top: 40%;
        border: 6px solid transparent;
        border-top: 6px solid #ffd700;
      }
    }
    > a {
      &::first-letter {
        text-transform: uppercase;
      }
      display: inline-block;
      margin-bottom: 6px;
      color: #fff;
      font-size: 14px;
      transition: color 0.3s ease;
      text-decoration: none;
      &:hover {
        color: #c4c4c6;
      }
    }
  }
  &__genres {
    display: none;
  }
  .active {
    display: none;
  }
  &__signIn {
    display: block;
    margin-bottom: 10px;
    position: relative;
    font-size: 14px;
    color: #9d9c9f;
    transition: color 0.3s ease;
    @include max-screen-768 {
      border: 1px solid #c4c4c6;
      border-radius: 3px;
      line-height: 40px;
      text-align: center;
    }
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 2px;
      width: 12px;
      height: 12px;
      background: url('/icons/login-icon.svg') no-repeat 0 0;
      background-size: cover;
      transition: opacity 0.3s ease;
      @include max-screen-768 {
        left: calc(50% - 35px);
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &:hover {
      color: #c4c4c6;
      &::before {
        opacity: 0.8;
      }
    }
  }
  &__register {
    font-size: 14px;
    color: #9d9c9f;
    transition: color 0.3s ease;
    &:hover {
      color: #c4c4c6;
    }
  }
  &__cabinet {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #9d9c9f;
    margin-bottom: 10px;
    @include max-screen-768 {
      max-width: 132px;
    }
  }
  &__user {
    color: white;
  }
  &__signOut {
    color: #9d9c9f;
    transition: color 0.3s ease;
    &:hover {
      color: #c4c4c6;
    }
  }
}

/*Бургер мобильное меню*/
.burger {
  &__bottom,
  &__footer,
  &__topper {
    position: relative;
    display: block;
    width: 100%;
    height: 3px;
    background: white;
    border: none;
    border-radius: 4px 4px 4px 4px;
    transition: all 0.2s, top 0.2s;
    transition-delay: 0.1s, 0s;
  }
  &__topper {
    top: 0;
  }
  &__bottom {
    top: 4px;
  }
  &__footer {
    top: 8px;
  }
}

main[menu-data='mobile-menu'] .burger {
  &__topper {
    top: 6px;
    transform: rotate(-45deg);
  }
  &__bottom {
    top: 2px;
    opacity: 0;
  }
  &__footer {
    opacity: 1;
    top: 0;
    transform: rotate(45deg);
  }
}

main[menu-data='mobile-menu'] .menu__middle {
  display: block;
}
